import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type {
  EnterpriseUsageOptions,
  HousingOptions,
} from '@/open-web/components/QuickSearch/types'
import { ENTERPRISE_USAGE_LIST, HOUSING_LIST } from '@/open-web/components/QuickSearch/utils'
import { getPriceAreaByPostalCode } from '@/open-web/services/getPriceAreaByPostalCode'
import { getActiveContractTemplate } from '@/open-web/utils/purchaseFlowUtils'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { GlobalApiError } from '@/shared/utils/errorClasses'

import { middleware } from '../middleware'
import type { Store } from '../types'

type HousingInfoState = {
  housingSize?: number
  estimatedConsumption?: number
  housingType?: HousingOptions //Private only
  usageOption?: EnterpriseUsageOptions

  postalCode?: string
  _isLoading?: boolean

  isMultipleAreas?: boolean
  deliveryArea?: PriceAreaCode

  shouldDisableContractSelect?: boolean
}

type HousingInfoActions = {
  setHousingSize: (size?: number) => void
  setHousingType: (type?: HousingOptions) => void
  setUsageOption: (option?: EnterpriseUsageOptions, shouldUpdateConsumption?: boolean) => void
  setEstimatedConsumption: (consumption?: number) => void

  setPostalCode: (postalCode?: string) => void
  setIsLoading: (_isLoading: boolean) => void

  setIsMultipleAreas: (isMultiple: boolean) => void
  setDeliveryArea: (area?: PriceAreaCode) => void

  requestDeliveryArea: (postalCode: string, streetAddress?: string, place?: string) => Promise<void>
  twoStepRequestDeliveryArea: (
    postalCode: string,
    streetAddress: string,
    place: string,
  ) => Promise<void>

  setShouldDisableContractSelect: (shouldDisable: boolean) => void
  resetHousingInfo: () => void
}

export type HousingInfoStore = {
  housingInfo: HousingInfoState & HousingInfoActions
}

export const initialState: HousingInfoState = {}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], HousingInfoStore> = (
  set,
  get,
) => ({
  housingInfo: {
    ...initialState,

    // Postal code actions
    setPostalCode: (postalCode) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.postalCode = postalCode
        }),
      ),
    setIsLoading: (_isLoading) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo._isLoading = _isLoading
        }),
      ),

    // Consumption related actions
    setHousingSize: (size) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.housingSize = size
        }),
      ),
    setHousingType: (type) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.housingType = type
        }),
      ),
    setUsageOption: (option, shouldUpdateConsumption) =>
      set(
        produce(get(), (state: Store) => {
          if (option && shouldUpdateConsumption) {
            state.housingInfo.estimatedConsumption = ENTERPRISE_USAGE_LIST[option].defaultUsage
          }
          state.housingInfo.usageOption = option
        }),
      ),
    setEstimatedConsumption: (consumption) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.estimatedConsumption = consumption
        }),
      ),

    // Delivery area actions
    setIsMultipleAreas: (isMultiple) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.isMultipleAreas = isMultiple
        }),
      ),
    setDeliveryArea: (area) =>
      set(
        produce(get(), (state: Store) => {
          state.housingInfo.deliveryArea = area
        }),
      ),

    requestDeliveryArea: async (postalCode, streetAddress, place) => {
      const { setPostalCode, setIsLoading, setIsMultipleAreas, setDeliveryArea } = get().housingInfo

      try {
        setIsLoading(true)

        const { data, error } = await getPriceAreaByPostalCode(postalCode, streetAddress, place)

        if (error) {
          throw new GlobalApiError({ apiError: error })
        }

        if (data) {
          const { primaryPriceArea, secondaryPriceArea } = data

          // If we have a defined DA - this condition should over the flow
          if (secondaryPriceArea ? primaryPriceArea === secondaryPriceArea : primaryPriceArea) {
            setDeliveryArea(primaryPriceArea)
            setPostalCode(postalCode)
            setIsMultipleAreas(false)

            return
          }

          setPostalCode(postalCode)
          setIsMultipleAreas(true)
        }
      } catch (error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    },

    /**
     * This request is used when we have all the data (postal code and address) from the beginning
     * Difference here is that it will fist try to fetch Pricing Area by postal code alone,
     * and if it gets 2 areas it does another fetch with the address data
     */
    twoStepRequestDeliveryArea: async (postalCode, streetAddress, place) => {
      const { setPostalCode, setIsLoading, setDeliveryArea } = get().housingInfo

      try {
        setIsLoading(true)

        const { data, error } = await getPriceAreaByPostalCode(postalCode)

        if (error) {
          throw new GlobalApiError({ apiError: error })
        }

        if (data) {
          const { primaryPriceArea, secondaryPriceArea } = data
          // If we have a defined DA - this condition should over the flow
          if (secondaryPriceArea ? primaryPriceArea === secondaryPriceArea : primaryPriceArea) {
            setDeliveryArea(primaryPriceArea)
            setPostalCode(postalCode)

            return
          }
        }

        const { data: secondReqData, error: secondReqError } = await getPriceAreaByPostalCode(
          postalCode,
          streetAddress,
          place,
        )

        if (secondReqError) {
          throw new GlobalApiError({ apiError: secondReqError })
        }

        setPostalCode(postalCode)
        setDeliveryArea(secondReqData?.primaryPriceArea)
      } catch (error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    setShouldDisableContractSelect: (shouldDisable) =>
      set(
        produce(get(), (state) => {
          state.housingInfo.shouldDisableContractSelect = shouldDisable
        }),
      ),
    resetHousingInfo: () =>
      set(
        produce(get(), (state) => {
          state.housingInfo.housingSize = undefined
          state.housingInfo.housingType = undefined
          state.housingInfo.usageOption = undefined
          state.housingInfo.estimatedConsumption = undefined

          state.housingInfo.isMultipleAreas = undefined
          state.housingInfo.deliveryArea = undefined

          state.housingInfo.shouldDisableContractSelect = undefined
        }),
      ),
  },
})

export const createHousingInfoSlice = middleware(creator, (prevState, _set, get, _store) => {
  const prevProps = prevState.housingInfo
  const {
    housingSize,
    housingType,
    estimatedConsumption,
    setEstimatedConsumption,
    setHousingSize,
  } = get().housingInfo

  const { selectContract, setSelectedAddons } = get().selectedContract

  const { contractProduct, contractTemplate, campaignConfiguration } =
    get().selectedContract.private

  /**
   * Set default size if appartment type is selected
   */
  if (housingType && housingType !== prevProps.housingType) {
    setHousingSize(HOUSING_LIST[housingType].defaultSize)

    return
  }

  /**
   * Calculate estimated consumption either on housing type
   * or on housing size change automatically.
   */
  if (
    housingType &&
    housingSize &&
    (prevProps.housingSize !== housingSize || prevProps.housingType !== housingType)
  ) {
    setEstimatedConsumption(housingSize * (HOUSING_LIST[housingType].usageMultiplier ?? 100))

    return
  }

  if (contractTemplate?.customerType !== 'PRIVATE') {
    // Below logic is only related to B2C customers
    return
  }

  //Sweden market feature
  //If there is two contracts with same type and duration, then we need to select correct one base on estimatedConsumption
  const isContractTemplateMightChange =
    contractProduct?.contractTemplates &&
    contractProduct?.contractTemplates?.length > 1 &&
    contractTemplate &&
    campaignConfiguration &&
    prevProps.estimatedConsumption !== estimatedConsumption

  try {
    if (isContractTemplateMightChange) {
      const contractTemplateForConsumption = getActiveContractTemplate(
        contractProduct,
        campaignConfiguration.campaignId,
        estimatedConsumption,
      )

      if (contractTemplateForConsumption?.tariffNo !== contractTemplate?.tariffNo) {
        selectContract(contractTemplateForConsumption, 'private')
        //Addons might differ between villa and apartment contractTemplates so we have to clean them up
        setSelectedAddons({}, 'private')
      }
    }
  } catch (error) {
    //Should never happened but...
    console.error(error)
  }
})
