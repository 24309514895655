import { isValid } from 'swedish-postal-code-validator'

import {
  IconApartment,
  IconBusiness,
  IconFlat,
  IconPrivateHouse,
  IconRowHouse,
  IconSummerCottage,
  IconSustainableSolutions,
} from '@fortum/elemental-ui'

import type { EnterpriseUsageList, FormFiledValues, HousingList, QuickSearchForm } from './types'

export const ENTERPRISE_USAGE_LIST: EnterpriseUsageList = {
  small: {
    icon: IconBusiness,
    defaultUsage: 15_000,
    maxUsage: 50_000,
  },
  medium: {
    icon: IconFlat,
    defaultUsage: 50_000,
    maxUsage: 100_000,
  },
  large: {
    icon: IconSustainableSolutions,
    defaultUsage: 100_000,
    maxUsage: 999_999,
  },
}

export const HOUSING_LIST: HousingList = {
  apartment: {
    icon: IconApartment,
    defaultSize: 50,
    usageMultiplier: 35,
  },
  rowHouse: {
    icon: IconRowHouse,
    defaultSize: 100,
    usageMultiplier: 100,
  },
  villa: {
    icon: IconPrivateHouse,
    defaultSize: 150,
    usageMultiplier: 100,
  },
  cabin: {
    icon: IconSummerCottage,
    defaultSize: 40,
    usageMultiplier: 100,
  },
}

export const QUICK_SEARCH_CONFIG: Record<keyof QuickSearchForm, Omit<FormFiledValues, 'texts'>> = {
  postalCode: {
    name: 'postalCode',
    rules: {
      pattern: /^\d{0,5}( \d{2})?$/,
      validate: (value) => isValid(value as QuickSearchForm['postalCode']),
    },
  },
  streetAddress: {
    name: 'streetAddress',
    rules: { required: true },
  },
  city: {
    name: 'city',
    rules: { required: true },
  },
  housingType: {
    name: 'housingType',
    rules: {
      required: true,
    },
  },
  housingSize: {
    name: 'housingSize',
    rules: {
      pattern: /^(0|[1-9]\d*)$/,
      required: true,
      min: 1,
    },
  },
  usageOption: {
    name: 'usageOption',
    rules: {
      required: true,
    },
  },
  estimatedConsumption: {
    name: 'estimatedConsumption',
    rules: {
      pattern: /^(0|[1-9]\d*)$/,
      required: true,
    },
  },
}
